import StatsService from '@/services/stats.service'
import EventBus from '@/util/EventBus'

export default {
  props: ['campaign', 'channel'],
  name: 'ShareModal',
  data: function () {
    return {
      showModal: false,
      url: '',
    }
  },
  methods: {
    getShareUrl () {
      StatsService.getShareUrl(this.campaign.campaignId)
        .then(
          (response) => {
            if (response.success) {
              this.showModal = true
              this.url = `${window.location.origin}/#/share/stats/${this.channel}/campaign/${this.campaign.campaignId}/${response.hash}`
            }
          },
          () => {
          },
        )
    },
    copyUrl () {
      navigator.clipboard.writeText(this.url)
    },

  },
  created () {
    EventBus.$on('getShareUrl', function (index) {
      this.getShareUrl()
    }.bind(this))
  },
}
