import { CALL_TO_ACTION_TYPE } from './CallToActionType'

export const EXTERNAL_LINK = {
  visible: true,
  cols: 1,
  text: 'Abrir enlace',
  url: 'https://example.com',
  example: '',
  customField: false,
  customFieldName: '',
  responseOption: 'call_to_action',
  type: CALL_TO_ACTION_TYPE.EXTERNAL_LINK,
}

export const EXTERNAL_PHONE = {
  visible: true,
  cols: 1,
  text: 'Llamar teléfono',
  phone: '',
  phonePrefix: '',
  countryPrefix: 'ES',
  example: '',
  customField: false,
  customFieldName: '',
  responseOption: 'call_to_action',
  type: CALL_TO_ACTION_TYPE.EXTERNAL_PHONE,
}

export const COPY_CODE = {
  visible: true,
  cols: 1,
  text: 'Copiar código de oferta',
  code: '',
  example: '',
  customField: false,
  customFieldName: '',
  responseOption: 'call_to_action',
  type: CALL_TO_ACTION_TYPE.COPY_CODE,
}

export const CALL_TO_ACTION = [
  { value: 1, text: 'Enlace externo', type: CALL_TO_ACTION_TYPE.EXTERNAL_LINK },
  { value: 2, text: 'Número de teléfono', type: CALL_TO_ACTION_TYPE.EXTERNAL_PHONE },
  { value: 3, text: 'Copiar código', type: CALL_TO_ACTION_TYPE.COPY_CODE },
]

export const COUNT_EXTERNAL_LINK = 2
export const COUNT_EXTERNAL_PHONE = 1
export const COUNT_COPY_CODE = 1
