import { Colors } from '@/classes/colors/Colors'
import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['campaignDataSource'],
  name: 'OpeningWhatsapp',
  components: { apexchart: VueApexCharts },
  data: function () {
    return {
      openedPercentage: 0,
      notOpenedPercentage: 0,
      series: [],
      ready: true,
      chartDataInfo: [],
      colors: Colors,
      chartOptions: {
        chart: {
          // offsetX: -150,
        },
        legend: {
          show: false,
        },
        colors: [Colors.colorChartOpened, Colors.colorChartNotOpened],
        labels: [],
      },
      showEmpty: false,
      emptySeries: [100],
      emptyChartOptions: {
        labels: [''],
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartEmptyBar],
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: undefined,
                formatter: function (val) {
                  return '' // val + '%'
                },
              },
            },
          },
        },
      },
    }
  },
  methods: {
    setPieChart (totals) {
      this.series = [totals.opened_total, totals.not_opened_total]

      this.openedPercentage = Math.round((totals.opened_total / totals.total) * 100)
      this.notOpenedPercentage = Math.round((totals.not_opened_total / totals.total) * 100)

      this.chartDataInfo = [
        {
          label: this.$t('Abiertos').toString(),
          value: this.openedPercentage + '%',
          color: Colors.colorChartOpened,
          total: totals.opened_total,
        },
        {
          label: this.$t('No abiertos').toString(),
          value: this.notOpenedPercentage + '%',
          color: Colors.colorChartNotOpened,
          total: totals.not_opened_total,
        },
      ]

      this.chartOptions.labels = [
        this.$t('Abiertos').toString() + ' ' + this.openedPercentage + '%',
        this.$t('No abiertos').toString() + ' ' + this.notOpenedPercentage + '%',
      ]

      this.resetReady()
    },
    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
  },
  created () {
    const data = this.campaignDataSource
    if (this.campaignDataSource.total > 0) {
      this.setPieChart(data)
    } else {
      this.showEmpty = true
    }
  },
}
