export default class HeadBoardTypeAuth {
  autoFill = true
  autocompleteText = null
  copyCodeText = null
  packageName = null
  hash = null
  copyCode = false
  securityRecommendation = false
  addExpireMinutes = false
  expireCodeMinutes = 1 // to 90

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.autoFill = data.autoFill
    this.autocompleteText = data.autocompleteText
    this.copyCodeText = data.copyCodeText
    this.packageName = data.packageName
    this.hash = data.hash
    this.copyCode = data.copyCode
    this.securityRecommendation = data.securityRecommendation
    this.addExpireMinutes = data.addExpireMinutes
    this.expireCodeMinutes = data.expireCodeMinutes
  }
}

