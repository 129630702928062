import { RESPONSE_TYPE } from './AutomaticResponseType'

export const RESPONSE_AUTOMATIC = {
  visible: true,
  cols: 1,
  text: 'Texto botón',
  responseOption: 'automatic_response',
  type: RESPONSE_TYPE.NORMAL,
}

export const DISABLED_MARKETING = {
  visible: true,
  cols: 1,
  textDisableMarketing: 'Detener promociones',
  textDisableFooter: '¿No te interesa? Toca "Detener promociones"',
  checkedDisableMarketing: false,
  responseOption: 'automatic_response',
  type: RESPONSE_TYPE.DISABLE_MARKETING,
}

export const AUTOMATIC_RESPONSE = [
  { value: 1, text: 'Respuesta automática', type: RESPONSE_TYPE.NORMAL, disabled: false },
  { value: 2, text: 'Desactivar marketing', type: RESPONSE_TYPE.DISABLE_MARKETING, disabled: false },
]

export const COUNT_DISABLE_MARKETING = 1
