import $t from '../util/Translator'

export class TrackingEvents {
  static DELIVERED_ID = 1;
  static OPENED_ID = 2;
  static CLICKED_ID = 3;
  static UNSUBSCRIBED_ID = 4;
  static HARD_BOUNCED_ID = 5;
  static COMPLAINT_ID = 6;
  static SENT_ID = 7;
  static SOFT_BOUNCED_ID = 8;
  static UNDELIVERED_ID = 9;
  static REJECTED_ID = 10;
  static EXPIRED_ID = 11;
  static UNSUBSCRIBED_LANDING_ID = 12;
  static LANDING_PAGE_CHARGE_ID = 13;
  static FORM_OPENED_ID = 14;
  static FORM_SUBMITTED_ID = 15;
  static FORM_FIELD_FILLED_ID = 16;
  static FORM_FIELD_SELECTED_ID = 17;
  static FORM_FIELD_CHECKED_ID = 18;
  static FORM_REJECTED_ID = 19;
  static VALIDATION_REJECTED_ID = 20;
  static RESPONDED_ID = 21;
  static READ_ID = 22;
  static FAILED_ID = 23;

  static events = [
    { id: 1, name: $t('Entregado'), color: '#2edb89' },
    { id: 2, name: $t('Abierto'), color: '#00bcd4' },
    { id: 3, name: $t('Clic'), color: '#8950FC' },
    { id: 4, name: $t('Baja'), color: '#ff9800' },
    { id: 5, name: 'Hard Bounce', color: 'red' },
    { id: 6, name: $t('Queja'), color: '#ff9800' },
    { id: 7, name: $t('Enviado'), color: '#607d8b' },
    { id: 8, name: 'Soft Bounce', color: '#cccccc' },
    { id: 9, name: $t('No entregado'), color: '#eeeeee' },
    { id: 10, name: $t('Rechazado'), color: '#E6413D' },
    { id: 11, name: $t('Expirado'), color: '#D53737' },
    { id: 12, name: $t('Baja Landing'), color: '#ff9800' },
    { id: 13, name: $t('Abierta Landing'), color: '#00bcd4' },
  ]
}
