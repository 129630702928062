import { Colors } from '@/classes/colors/Colors'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DeliveredDetailWhatsapp',
  props: {
    campaignDataSource: {
      type: Object,
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data: function () {
    return {
      series: [{
        name: 'Entregados',
        data: [44],
      }, {
        name: 'No entregados',
        data: [53],
      }, {
        name: 'Pendientes',
        data: [12],
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
    }
  },
}
