import { GraphicData } from '@/classes/stats/GraphicData'
import { Colors } from '@/classes/colors/Colors'
export class WhatsappGraphicData {
  whatsappCalculatedData = null
  thatContext = null
  interactionItems = []

  constructor (whatsappCalculatedData, thatContext) {
    this.whatsappCalculatedData = whatsappCalculatedData
    this.thatContext = thatContext
  }

  getCardWhatsappGraphicData () {
    return [
      {
        title: this.thatContext.$t('Enviados').toString(),
        subTitle: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_sent,
        percentage: this.sentPercentage(
            this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_sent,
            this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        colorClass: 'gradient-standard',
        percentageColorClass: 'blue',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Entregados').toString(),
        subTitle: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered,
        percentage: this.deliveredPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        colorClass: 'gradient-success',
        percentageColorClass: 'green',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Lecturas').toString(),
        subTitle: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_read,
        percentage: this.readPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_read,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        colorClass: 'gradient-alert',
        percentageColorClass: 'alert',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Bajas').toString(),
        subTitle: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_unsubscribed,
        percentage: this.undeliveredPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_unsubscribed,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        colorClass: 'gradient-danger',
        percentageColorClass: 'danger',
        cols: 3,
      },
    ]
  }

  getDeliveredRatioGraphicGraphicData () {
    const graphicData = new GraphicData(
      this.thatContext.$t('Ratio de entrega'),
      [
        {
          label: this.thatContext.$t('Enviados').toString(),
          value: '<b>' + this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_sent + '</b> / ',
          total: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        },
        {
          label: this.thatContext.$t('Entregados').toString(),
          value: '<b>' + this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered + '</b> / ',
          total: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        },
        {
          label: this.thatContext.$t('Respondidos').toString(),
          value: '<b>' + this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_responded + '</b> / ',
          total: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total
          ,
        },
        {
          label: this.thatContext.$t('No entregados').toString(),
          value: '<b>' + this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_undelivered + '</b> / ',
          total: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        },
        {
          label: this.thatContext.$t('Fallidos').toString(),
          value: '<b>' + this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_failed + '</b> / ',
          total: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        },
        {
          label: this.thatContext.$t('Leidos').toString(),
          value: '<b>' + this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_read + '</b> / ',
          total: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        },
      ],
      [
        this.sentPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_sent,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        this.deliveredPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        this.respondedPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_responded,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        this.undeliveredPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_undelivered,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        this.failedPercentage(
            this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_failed,
            this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        this.readPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_read,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
      ],
      [
        this.thatContext.$t('Enviados').toString(),
        this.thatContext.$t('Entregados').toString(),
        this.thatContext.$t('Respondidos').toString(),
        this.thatContext.$t('No entregados').toString(),
        this.thatContext.$t('Fallidos').toString(),
        this.thatContext.$t('Leidos').toString(),
      ],
      this.deliveredPercentage(
        this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered,
        this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
      ),
      '',
      0,
      0,
      true,
      350,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.PENDING,
        Colors.statistics.UNDELIVERED_TOTAL,
        Colors.statistics.FAILED_TOTAL,
        Colors.statistics.CLICKS,
      ],
    )
    graphicData.legendProps.fontSize = '10px'

    return graphicData
  }

  getDeliveredSessionRatioGraphicGraphicData (sumTotal) {
    const graphicData = new GraphicData(
        this.thatContext.$t('Ratio de entrega'),
        [
          {
            label: this.thatContext.$t('Enviados').toString(),
            value: '<b>' + sumTotal.get('sent') + '</b> / ',
            total: sumTotal.get('total'),
          },
          {
            label: this.thatContext.$t('Entregados').toString(),
            value: '<b>' + sumTotal.get('delivered') + '</b> / ',
            total: sumTotal.get('total'),
          },
          {
            label: this.thatContext.$t('Respondidos').toString(),
            value: '<b>' + sumTotal.get('responded') + '</b> / ',
            total: sumTotal.get('total'),
          },
          {
            label: this.thatContext.$t('No entregados').toString(),
            value: '<b>' + sumTotal.get('undelivered') + '</b> / ',
            total: sumTotal.get('total'),
          },
          {
            label: this.thatContext.$t('Fallidos').toString(),
            value: '<b>' + sumTotal.get('failed') + '</b> / ',
            total: sumTotal.get('total'),
          },
          {
            label: this.thatContext.$t('Leidos').toString(),
            value: '<b>' + sumTotal.get('read') + '</b> / ',
            total: sumTotal.get('total'),
          },
        ],
        [
          this.sentPercentage(
              sumTotal.get('sent'),
              sumTotal.get('total'),
          ),
          this.deliveredPercentage(
              sumTotal.get('delivered'),
              sumTotal.get('total'),
          ),
          this.respondedPercentage(
              sumTotal.get('responded'),
              sumTotal.get('total'),
          ),
          this.undeliveredPercentage(
              sumTotal.get('undelivered'),
              sumTotal.get('total'),
          ),
          this.failedPercentage(
              sumTotal.get('failed'),
              sumTotal.get('total'),
          ),
          this.readPercentage(
              sumTotal.get('read'),
              sumTotal.get('total'),
          ),
        ],
        [
          this.thatContext.$t('Enviados').toString(),
          this.thatContext.$t('Entregados').toString(),
          this.thatContext.$t('Respondidos').toString(),
          this.thatContext.$t('No entregados').toString(),
          this.thatContext.$t('Fallidos').toString(),
          this.thatContext.$t('Leidos').toString(),
        ],
        this.deliveredPercentage(
            sumTotal.get('delivered'),
            sumTotal.get('total'),
        ),
        '',
        0,
        0,
        true,
        350,
        [
          Colors.statistics.SENT_TOTAL,
          Colors.statistics.DELIVERED_TOTAL,
          Colors.statistics.PENDING,
          Colors.statistics.UNDELIVERED_TOTAL,
          Colors.statistics.FAILED_TOTAL,
          Colors.statistics.CLICKS,
        ],
    )
    graphicData.legendProps.fontSize = '10px'

    return graphicData
  }

  getClicksRatioGraphicData () {
    return new GraphicData(
      this.thatContext.$t('Ratio de CLICS').toString(),
      [
        {
          label: this.thatContext.$t('Enviados').toString(),
          value: '<b>' + this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_sent + '</b> / ',
          total: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        },
        {
          label: this.thatContext.$t('Entregados').toString(),
          value: '<b>' + this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered + '</b> / ',
          total: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_sent,
        },
        {
          label: this.thatContext.$t('Clics únicos').toString(),
          value: '<b>' + this.whatsappCalculatedData.totals.whatsapp_sendings_click_tracking_total + '</b> / ',
          total: this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered,
        },
      ],
      [
        this.sentPercentage(
            this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_sent,
            this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_total,
        ),
        this.deliveredPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_sent,
        ),
        this.clicsPercentage(
          this.whatsappCalculatedData.totals.whatsapp_sendings_click_tracking_total,
          this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered,
        ),
      ],
      [this.thatContext.$t('Enviados').toString(), this.thatContext.$t('Entregados').toString(), this.thatContext.$t('Clics').toString()],
      this.clicsPercentage(
        this.whatsappCalculatedData.totals.whatsapp_sendings_click_tracking_total,
        this.whatsappCalculatedData.totals.whatsapp_sendings_tracking_delivered,
      ),
      this.thatContext.$t('Clics únicos'),
      this.whatsappCalculatedData.totals.whatsapp_sendings_click_tracking_total,
      0,
      true,
      350,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.CLICKS,
      ],
    )
  }

  sentPercentage (sentTotal, total) {
    return parseInt(sentTotal ?? 0) === 0 ? 0 : parseFloat((sentTotal / total) * 100).toFixed(0)
  }

  deliveredPercentage (deliveredTotal, sentTotal) {
    return parseInt(sentTotal ?? 0) === 0 ? 0 : parseFloat((deliveredTotal / sentTotal) * 100).toFixed(0)
  }

  respondedPercentage (respondedTotal, sentTotal) {
    return parseInt(sentTotal ?? 0) === 0 ? 0 : parseFloat((respondedTotal / sentTotal) * 100).toFixed(0)
  }

  undeliveredPercentage (undeliveredTotal, sentTotal) {
    return parseInt(sentTotal ?? 0) === 0 ? 0 : parseFloat((undeliveredTotal / sentTotal) * 100).toFixed(0)
  }

  failedPercentage (failedTotal, sentTotal) {
    return parseInt(sentTotal ?? 0) === 0 ? 0 : parseFloat((failedTotal / sentTotal) * 100).toFixed(0)
  }

  clicsPercentage (clicksTotal, deliveredTotal) {
    return parseInt(deliveredTotal ?? 0) === 0 ? 0 : parseFloat((clicksTotal / deliveredTotal) * 100).toFixed(0)
  }

  readPercentage (readTotal, deliveredTotal) {
    return parseInt(deliveredTotal ?? 0) === 0 ? 0 : parseFloat((readTotal / deliveredTotal) * 100).toFixed(0)
  }
}
