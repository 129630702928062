import StatsService from '@/services/stats.service'

export default {
  props: ['campaign'],
  name: 'ShareButton',
  data: function () {
    return {
      showLink: false,
      isEnableLink: false,
      url: null,
    }
  },

  methods: {
    enableOrDisableShareLink () {
      if (this.showLink) {
        StatsService.enableShareUrl(this.campaign.campaignId)
          .then(
            (response) => {
              if (response.success) {
                this.isEnableLink = true
                this.getShareUrl()
              }
            },
            () => {
            },
          )
      } else {
        StatsService.disableShareUrl(this.campaign.campaignId)
          .then(
            (response) => {
              if (response.success) {
                this.isEnableLink = false
              }
            },
            () => {
            },
          )
      }
    },

    getShareUrl () {
      StatsService.getShareUrl(this.campaign.campaignId)
        .then(
          (response) => {
            if (response.success) {
              this.url = `${window.location.origin}/#/share/stats/${this.campaign.slug}/campaign/${this.campaign.campaignId}/${response.hash}`
            }
          },
          () => {
          },
        )
    },
    copyUrl () {
      navigator.clipboard.writeText(this.url)
    },
  },

  watch: {
    campaignId () {
      if (this.campaignId) {
        StatsService.checkShareUrl(this.campaignId)
        .then(
          (response) => {
            if (response.success && response.active) {
              this.isEnableLink = true
              this.showLink = true
            }
          },
          () => {
          },
        )
      }
    },
  },
}
