import getEnv from '@/util/env'
import HeadboardType from '@/models/whatsapp-editor/HeadboardType.js'
import { TextFormatParser } from '@/classes/TextFormatParser.js'

export default {
  name: 'MessagePreview',
  props: {
    whatsappEditor: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      headboardTypes: HeadboardType.headboardTypes,
      textFormatParser: new TextFormatParser(),
        strong: {
          format: '*',
          init: 1,
          finish: 2,
          start: '<strong>',
          end: '</strong>',
        },
        em: {
          format: '_',
          init: 1,
          finish: 2,
          start: '<em>',
          end: '</em>',
        },
        strike: {
          format: '~',
          init: 1,
          finish: 2,
          start: '<strike>',
          end: '</strike>',
        },
        code: {
          format: '`',
          init: 1,
          finish: 3,
          row: 1,
          start: '<code>',
          end: '</code>',
        },
    }
  },
  created () {},
  mounted () {},
  computed: {
    messagePreviewAuth: function () {
      return this.whatsappEditor.message.replace('{COL+}', '<strong>{COL+}</strong>')
    },
    messagePreview: function () {
      if (!this.whatsappEditor.message) {
        return this.$t('Contenido del mensaje').toString()
      }

      return this.textFormatParser.formatHtmlMessage(this.whatsappEditor.message)
    },
    automaticResponse: function () {
      const data = this.whatsappEditor.automaticResponses.filter((automaticResponse) => {
        return automaticResponse.visible !== false
      })

      return data
    },
  },
  watch: {},
  methods: {
    validateWhatsappHeaderType () {
      return [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.whatsappEditor.selectedHeadboardType)
    },
    validateWhatsappCallActionType () {
      if (
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.CALL_ACTION_TEXT ||
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.CALL_ACTION_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    validateWhatsappResponseType () {
      if (
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.RESPONSE_TEXT ||
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.RESPONSE_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    changeWhatsappPreview (index) {
      const object = this.preview.contactsFieldsValue[index]
      const contactFields = []
      for (const index in object) {
        contactFields[index] = object[index]
      }

      let message = this.whatsappEditor.message
      message = this.replaceUrlFields(message)

      for (let i = 0; i < this.fields.currentFieldsMessage.length; i++) {
        const field = this.fields.currentFieldsMessage[i]
        message = message.replace('{' + this.fields.currentFieldsMessage[i] + '}', contactFields[field])
      }

      message = message.replaceAll('{LAND_URL}', '<span class="sample-url land-url">https://nrs.so/sample</span>')
      message = message.replaceAll('{UNSUB_URL}', '<span class="sample-url unsub-url">https://nrs.so/sample</span>')

      message = message.replace(/null/g, this.$t('Sin datos'))

      return message
    },
    // eslint-disable-next-line complexity
    replaceUrlFields (message) {
      for (let i = 0; i < this.fields.currentUrlFieldsMessage.length; i++) {
        const explodeUrlField = this.fields.currentUrlFieldsMessage[i].split('_')
        let value = ''
        switch (explodeUrlField[0]) {
          case 'FORM':
            value = this.generateSampleUrl(null, 'form-url', `data-form-id="${ explodeUrlField[1] }"`)
            break
          case 'UNSUB':
            value = this.generateSampleUrl(null, 'unsub-url')
            break
          case 'ATTACHMENT':
            value = this.generateSampleUrl(this.getAttachmentUrl(explodeUrlField[1]))
            break
          case 'URL':
            value = this.generateSampleUrl(this.availableUrls[explodeUrlField[1]]?.url_preview)
            break
          }
        if (!value) {
          this.generatedUrls[this.fields.currentUrlFieldsMessage[i]] = this.generatedUrls[this.fields.currentUrlFieldsMessage[i]] || this.generateSampleUrl()
          value = this.generatedUrls[this.fields.currentUrlFieldsMessage[i]]
        }
        if (['LAND_URL', 'UNSUB_URL'].indexOf(this.fields.currentUrlFieldsMessage[i]) < 0) {
          message = message.replace('{' + this.fields.currentUrlFieldsMessage[i] + '}', value)
        }
      }
      return message
    },
    getAttachmentUrl (field) {
      // eslint-disable-next-line no-case-declarations
      let attachment = this.whatsappEditor.attachments.filter((attachmentIn) => {
        return parseInt(field) === attachmentIn.id
      })

      attachment = attachment[0]
      const url = attachment ? getEnv('VUE_APP_API_URL') + '' + attachment.url : null

      return url
    },
    generateRandomShortUrl (url = null) {
      const randStr = (Math.random() + 1).toString(36).substring(6)
      let randUrl = `https://nrs.so/${ randStr }`
      if (url) {
        randUrl = `<a target="_blank" href="${ url }">${ randUrl }</a>`
      }
      return `<span class="sample-url">${ randUrl }</span>`
    },
    generateSampleUrl (url = null, addClass = null, objectId = null) {
      let sampleUrl = 'https://nrs.so/sample'
      if (url) {
        sampleUrl = `<a target="_blank" href="${ url }">${ sampleUrl }</a>`
      }
      return `<span ${ objectId } class="sample-url ${addClass}">${ sampleUrl }</span>`
    },
    handleClick (e) {
      if (e.target.matches('.land-url')) {
        this.$emit('showLanding')
      }

      if (e.target.matches('.form-url')) {
        const formId = e.target.getAttribute('data-form-id')
        this.$emit('showForm', formId)
      }

      if (e.target.matches('.url-url')) {
        const urlId = e.target.getAttribute('data-url-id')
        this.$emit('showUrl', urlId)
      }

      if (e.target.matches('.unsub-url')) {
        this.$emit('showUnsubscribe')
      }
    },
  },
}
